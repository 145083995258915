import {defineStore} from "pinia";
import router from "@/router";
import {agencyService} from "@/core/api/agency.api";
import {useLocationStore} from "@/stores/locationStore";
import {ToastMessage} from "@/core/services/general.service";
import {errorService} from "@/core/services/error.service";

export const useAgencyStore = defineStore('agencyStore', {
        state: () => ({
            countries: [],
            edit_mode: false,
            edit_details: [],
            selected_agency: [],
            active_package_types: [],
            diagram: {
                packages: [],
                displayed_markup_package_id: 1, // Hotel
                base_price: null,
            },
            markups: [],
        }),
        getters: {
            getEditSubModuleData(state) {
                if (state.edit_mode && state.edit_details) {
                    return {
                        id: state.edit_details.id,
                        parent_agency_id: state.edit_details.parent_agency_id,
                        title: state.edit_details.title,
                        email: state.edit_details.email,
                        country: state.edit_details.country_iso,
                        state: state.edit_details.state_id,
                        city: state.edit_details.city_id,
                        address: state.edit_details.customized.address,
                        country_code: state.edit_details.customized.country_code,
                        phone: state.edit_details.customized.phone,
                        fax: state.edit_details.customized.fax,
                        post_code: state.edit_details.customized.post_code,
                        web_site: state.edit_details.web_site,
                        agency_code: state.edit_details.agency_code,
                        selected_languages: state.edit_details.customized.languages,
                        currencies: state.edit_details.customized.currencies,
                    };
                }
                return null;
            },
            getAgencySelfMarkups(state) {
                if (state.selected_agency && state.selected_agency.current_markups) {
                    return state.selected_agency.current_markups.just_self_markups;
                }
                return [];
            },

        },
        actions: {

            async getAgencies() {
                return await agencyService.getAgencies()
                    .then(response => response.data.agencies)
                    .catch(error => {
                        errorService.apiClientError(error);
                        return [];
                    })
            },

            async getActiveLanguages() {
                return await agencyService.getActiveLanguages()
                    .then(response => response.data.languages)
                    .catch(error => {
                        errorService.apiClientError(error);
                        return [];
                    })
            },

            async getCurrencies() {
                return await agencyService.getCurrencies()
                    .then(response => response.data.currencies)
                    .catch(error => {
                        errorService.apiClientError(error);
                        return [];
                    })
            },

            async getCountries() {
                return await useLocationStore().getCountries()
                    .then(response => response.data.countries)
                    .catch(error => {
                        errorService.apiClientError(error);
                        return [];
                    })
            },

            async getStates(country_id) {
                return await useLocationStore().getStates(country_id)
                    .then(response => response.data.states)
                    .catch(error => {
                        errorService.apiClientError(error);
                        return [];
                    })
            },

            async getCities(state_id) {
                return await useLocationStore().getCities(state_id)
                    .then(response => response.data.cities)
                    .catch(error => {
                        errorService.apiClientError(error);
                        return [];
                    });
            },

            async createOrUpdateAgency(agency_data) {
                if (this.edit_mode)
                    return await agencyService.updateAgency(agency_data);
                else
                    return await agencyService.createAgency(agency_data);
            },

            async saveAgencyParentRelationship(agency_data) {
                await agencyService.saveAgencyParentRelationship(agency_data)
                    .then(response => {
                        ToastMessage('success', response.data.message, 'Successfully!');
                    })
                    .catch(error => {
                        errorService.apiClientError(error);
                    })
            },

            async getAgencyDetails(agency_id) {
                return await agencyService.getAgencyDetails(agency_id)
                    // .then(response => response.data.agency)
                    .then(response => {
                        this.selected_agency = response.data.agency;
                        this.active_package_types = response.data.active_package_types;
                    })
                    .catch(error => {
                        //console.log(error)
                        errorService.apiClientError(error);
                        return [];
                    });
            },

            async getDiagramData() {
                return await agencyService.getDiagramData()
                    .then(response => response.data)
                    .catch(error => {
                        errorService.apiClientError(error);
                        return [];
                    })
            },

            async createAgencyMarkup(data) {
                return await agencyService.createAgencyMarkup(data);
            },

            async updateAgencyMarkup(data) {
                return await agencyService.updateAgencyMarkup(data);
            },

            async deleteAgencyMarkup(markup_id) {
                return await agencyService.deleteAgencyMarkup(markup_id);
            },

            async createAgencyWallet(form_data) {
                return await agencyService.createAgencyWallet(this.selected_agency.id, form_data);
            },

            async updateAgencyWallet(form_data) {
                return await agencyService.updateAgencyWallet(this.selected_agency.id, form_data);
            },

            async loadBalance(form_data) {
                return await agencyService.loadBalance(this.selected_agency.id, form_data);
            },
        }
    })
;
