import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/js/bootstrap.bundle';
import '@/assets/scss/app.scss';
import VueFeather from 'vue-feather';
import Breadcrumbs from './components/bread_crumbs';

import {createPinia} from 'pinia';
import 'toastr/build/toastr.min.css?v=1';
import '@/assets/css/toastr.min.css';

import {useAuthStore} from '@/stores/authStore';

import 'vue-toastification/dist/index.css';
import 'devextreme/dist/css/dx.material.blue.light.css';
import '@/assets/css/dx-diagram.min.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Select2 from 'vue3-select2-component';
import '@/assets/css/custom.css';
import '@/assets/css/select2.bootstrap.css';
import i18n from '@/core/locales/locales.config';
import '@/core/validation/vee-validate-config';
import CKEditor from '@mayasabha/ckeditor4-vue3';
import 'animate.css';
import {vClickOutside} from "@/core/services/custom.directive.service";
import Lightbox from 'vue-easy-lightbox'
import VueEasyLightbox from "vue-easy-lightbox";


const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(store);
app.use(i18n);
app.use(CKEditor);
app.use(LoadingPlugin);

app.component(VueFeather.name, VueFeather);
app.component('multiselect', Multiselect);
app.component('Breadcrumbs', Breadcrumbs);
app.component('Select2', Select2);
app.component('VueEasyLightbox', VueEasyLightbox);

app.directive('click-outside', vClickOutside);

const authStore = useAuthStore();
authStore.checkTokenExpiry();

setInterval(() => {
    authStore.checkTokenExpiry();
}, 60000);

await authStore.authInit();


app.mount('#app');
